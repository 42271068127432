.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* background stuff */
:root {
  --from-color: #3B82F6;
  --to-color: #34D399;
}

/* box */
.App-header {
  background-image: linear-gradient(to right, var(--from-color), var(--to-color)); /* Example: Changing to a gradient from pink to orange */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* login */
.SignOut {
  position: fixed;
  top: 20px;
  right: 20px;
  /* ...other styles */
  background-color: #3B82F6; /* Blue */
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    color: black; /* White text for better visibility on blue background */
    border-radius: 4px; /* Optional: for rounded corners */
    font-size: 1rem; /* Optional: adjust size to preference */
}

.firebaseui-auth-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;  /* Ensures it's on top of other elements */
}